import React from "react";
import { Link } from "react-router-dom";
import EbookCover from "../../img/ebook cover.png"
import coverImage from "../../img/coverimage.png"

const AttentionBanner = () => {
  return (
    <div className="w-full bg-gradient-to-r from-amber-300 to-amber-500 rounded-lg flex flex-row px-10 justify-center items-center ">
        <img src={coverImage} alt="Ebook Cover" className="w-[4%] mt-1.5 " />
      <h1 className="py-2 px-10 text-lg font-bold">Don't Get Left Behind: Master AI for Construction Today</h1>
      <Link to="/ebook" className="">
      <button className="bg-black text-white px-4 py-2 rounded-lg">Download the Ebook!</button>
      </Link>
    </div>
  );
};

export default AttentionBanner;
