import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DWHeader from './DigitalWorkers-site/DWHeader';
import { scrollToSection } from "../utils";
import EbookCover from '../img/ebook cover.png'
import { supabase } from "../utils";

const BUCKET_NAME = 'ebooks';
const FILE_URL = 'https://qfktimnmlcnfowxuoune.supabase.co/storage/v1/object/sign/ebooks/AI%20Primer%20for%20the%20Construction%20Industry.pdf?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJlYm9va3MvQUkgUHJpbWVyIGZvciB0aGUgQ29uc3RydWN0aW9uIEluZHVzdHJ5LnBkZiIsImlhdCI6MTczNjQ2OTgwMSwiZXhwIjo0ODU4NTMzODAxfQ.TsMkazJtcSAMr6GyhLtrXESqfKgCsy5fKSmlTRAFZEg&t=2025-01-10T00%3A43%3A21.760Z';

function EbookLead() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
 



 // Empty dependency array ensures this runs once

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDownload = useCallback(async () => {
    window.open(FILE_URL, '_blank');
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear any previous errors
  
    // 1. Insert form data into Supabase
    const { data, error } = await supabase.from("ebook_leads").insert([
      {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        company: formData.company,
      },
    ]);
  
    if (error) {
      if (error.code === '23505') {
        setError('This email has already been used to download the ebook.');
        return;
      } else {
        setError('An error occurred. Please try again later.');
        console.error("Error inserting data:", error);
      }
      return;
    }
  
    console.log("Data inserted successfully:", data);
  
    setSubmitted(true);
    setTimeout(() => {
      handleDownload();
    }, 1000);
  };
  

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="min-h-screen main">
      <DWHeader width={width} scroll={scrollToSection} />
      <div className="py-12 px-4 sm:px-6 lg:px-8 pt-[150px] ">
      
        <div className="max-w-7xl mx-auto">
          <div className="bg-white rounded-2xl  overflow-hidden">
            <div className="grid grid-cols-1 md:grid-cols-2">
              {/* Left side - Image and Form */}
              <div className="p-8 bg-gray-200">
                <div className="mb-8">
                  <img
                    src={EbookCover}
                    alt="E-book Cover"
                    className="w-full max-w-md mx-auto rounded-lg shadow-lg"
                  />
                </div>
                
                {!submitted ? (
               
        <form onSubmit={handleSubmit} className="space-y-6">
                    {error && (
                      <div className="bg-red-50 text-red-600 p-3 rounded-md mb-4">
                        {error}
                      </div>
                    )}
                    <div className="grid grid-cols-1 gap-6">
                      <div>
                        <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                          First Name
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          id="firstName"
                          required
                          value={formData.firstName}
                          onChange={handleChange}
                          className="block w-full bg-white flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 w-[200px] shadow-[5px_5px_0px_0px_#1a202c]"
                        />
                      </div>
                      <div>
                        <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                          Last Name
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          id="lastName"
                          required
                          value={formData.lastName}
                          onChange={handleChange}
                          className="block w-full bg-white flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 w-[200px] shadow-[5px_5px_0px_0px_#1a202c]"
                        />
                      </div>
                      <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          required
                          value={formData.email}
                          onChange={handleChange}
                          className="block w-full bg-white flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 w-[200px] shadow-[5px_5px_0px_0px_#1a202c]"
                        />
                      </div>
                      <div>
                        <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                          Company
                        </label>
                        <input
                          type="text"
                          name="company"
                          id="company"
                          required
                          value={formData.company}
                          onChange={handleChange}
                          className="block w-full bg-white flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 w-[200px] shadow-[5px_5px_0px_0px_#1a202c]"
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                     className="border-2 border-black p-2 mt-4 font-bold text-lg shadow-[5px_5px_0px_0px_#1a202c] bg-white hover:bg-amber-400"
                    >
                      Download E-book
                    </button>
                  </form>
                ) : (
                  <div className="text-center p-8 bg-emerald-50 rounded-lg">
                    <h3 className="text-xl font-semibold text-emerald-700 mb-2">
                      Thank you for your interest!
                    </h3>
                    <p className="text-emerald-600">
                      Your download will begin automatically. If it doesn't,{' '}
                      <button 
                        onClick={handleDownload}
                        className="text-emerald-700 underline ml-1"
                      >
                        click here
                      </button>
                    </p>
                  </div>
                )}
              </div>

              {/* Right side - Description */}
              <div className="p-8 lg:p-12 bg-white">
                <h2 className="text-3xl font-bold text-gray-900 mb-6">
                  AI Primer for the Construction Industry
                </h2>
                <div className="prose prose-emerald max-w-none">
                  <p className="text-lg text-gray-600 mb-6">
                    This ebook serves as a guide to understanding the transformative technology of artificial intelligence (AI) and its impact on the construction industry. It explores <strong>AI's potential to address key industry challenges, such as labor shortages, rising costs, and sustainability demands</strong>. The ebook delves into practical applications of AI across different stages of construction projects, from design and planning to safety and quality control. It also provides guidance for construction companies on assessing their AI readiness, identifying relevant AI solutions, and implementing AI strategies.
                  </p>
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">
                    In this ebook you will learn:
                  </h3>
                  <div className="space-y-6">
                    <div>
                      <h4 className="text-lg font-semibold text-black mb-2">Demystifying AI in Construction</h4>
                      <p className="text-gray-600">
                        A clear and accessible explanation of AI concepts, including machine learning and deep learning, and addresses common myths surrounding AI in construction.
                      </p>
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-black mb-2">Practical Applications</h4>
                      <p className="text-gray-600">
                        Concrete examples of how AI is being successfully implemented across various stages of construction projects.
                      </p>
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-black mb-2">AI Adoption Guide</h4>
                      <p className="text-gray-600">
                        Practical advice for construction companies on assessing their AI readiness and identifying the right AI solutions for their needs.
                      </p>
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-black mb-2">Industry Benefits</h4>
                      <p className="text-gray-600">
                        The range of benefits AI offers to the construction industry, including increased efficiency, improved safety, reduced costs, and enhanced sustainability.
                      </p>
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-black mb-2">Future Preparation</h4>
                      <p className="text-gray-600">
                        The importance of understanding AI and its potential to reshape the construction industry, equipping readers with the knowledge to thrive in this evolving landscape.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EbookLead; 