import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import PmAssistant from "./App";
import Inspection from "./Inspection";
import BlogsList from "./BlogsList";
import Blog from "./components/Blog";
import QAQC from "./QAQC";
import Safety from "./Safety";
import Submittal from "./Submittal";
import Communication from "./Communication";
import Meetings from "./Meetings";
import BlogEditor from "./components/BlogEditor";
import ThankYou from "./ThankYou";
import DigitalWorkers from "./DigitalWorkers";
import EbookLead from "./components/EbookLead";

const ScrollToSection = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const section = document.querySelector(hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  return null;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToSection />
      <Routes>
        <Route index element={<DigitalWorkers />} />
        <Route path="/blog" element={<BlogsList />} />
        <Route path="/blog/:blogId/:slug" element={<Blog />} />
        {/* <Route path="/inspection" element={<Inspection />} />
        <Route path="/qaqc" element={<QAQC />} />
        <Route path="/safety" element={<Safety />} />
        <Route path="/submittals" element={<Submittal />} />
        <Route path="/communication" element={<Communication />} />
        <Route path="/meeting-minutes" element={<Meetings />} />
        <Route path="/assistant" element={<PmAssistant />} /> */}
        <Route path="/editor" element={<BlogEditor />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/ebook" element={<EbookLead />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
