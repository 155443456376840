import React, { useState, useEffect } from "react";
// import { createClient } from '@supabase/supabase-js';
import { supabase } from "../../utils";
import emailjs from "@emailjs/browser";

// const supabaseUrl = process.env.REACT_APP_SB_URL;
// const supabaseKey = process.env.REACT_APP_SB_KEY;
// const supabase = createClient(supabaseUrl, supabaseKey);

const YOUR_SERVICE_ID = "service_o23ygrn";
const YOUR_TEMPLATE_ID = "template_h1ua7ne";
const YOUR_PUBLIC_KEY = "e85MPTZWEGwGtuNZC";

const DWForm = ({ width, content }) => {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const { partners } = content;


  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   // Insert the form data into Supabase table using parameterized query
  //   const { data, error } = await supabase
  //     .from('prospects')
  //     .insert([{ first_name: firstname.trim(), last_name: lastname.trim(), email: email.trim() }]);

  //   if (error) {
  //     console.error('Error inserting data:', error);
  //   } else {
  //     console.log('Data inserted successfully:', data);
  //     // Do something else after successful submission
  //   }

  //   // Reset the form fields
  //   setFirstname('');
  //   setLastname('');
  //   setEmail('');
  //   setSubmitted(true);
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   // 1. Insert form data into Supabase
  //   const { data, error } = await supabase.from("prospects").insert([
  //     {
  //       first_name: firstname.trim(),
  //       last_name: lastname.trim(),
  //       email: email.trim(),
  //       product: "meeting minutes",
  //     },
  //   ]);

  //   if (error) {
  //     console.error("Error inserting data:", error);
  //   } else {
  //     console.log("Data inserted successfully:", data);

  //     // 2. Send email using EmailJS
  //     emailjs
  //       .sendForm(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, e.target, YOUR_PUBLIC_KEY)
  //       .then(
  //         (result) => {
  //           console.log("Email sent:", result.text);
  //         },
  //         (error) => {
  //           console.log("Error sending email:", error.text);
  //         }
  //       );
  //   }

  //   // 3. Reset form fields and display success message
  //   setFirstname("");
  //   setLastname("");
  //   setEmail("");
  //   setSubmitted(true);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // 1. Insert form data into Supabase
    const { data, error } = await supabase.from("prospects").insert([
      {
        first_name: firstname.trim(),
        last_name: lastname.trim(),
        email: email.trim(),
        product: "meeting minutes",
      },
    ]);

    if (error) {
      console.error("Error inserting data:", error);
    } else {
      console.log("Data inserted successfully:", data);

      // 2. Send email using EmailJS
      emailjs
        .sendForm(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, e.target, YOUR_PUBLIC_KEY)
        .then(
          (result) => {
            console.log("Email sent:", result.text);
          },
          (error) => {
            console.log("Error sending email:", error.text);
          }
        );
    }

    // 3. Reset form fields and display success message
    setFirstname("");
    setLastname("");
    setEmail("");
    setSubmitted(true);

    // 4. Redirect to thank you page
    window.location.href = "/thank-you";
  };

  return (
    <>
      {width > 660 ? (
        <div
          id="footer"
          className="bg-black text-white border-white px-20 mx-20 rounded-3xl py-20 min-h-[650px] flex flex-col"
        >
         
          <div className="">
            <div className="font-bold">Partners</div>
            <div className="flex flex-row items-center">
              {Object.keys(partners).map((key) => (
                <div className="mr-4 w-[10%]" key={key}>
                  {partners[key].image}
                </div>
              ))}
            </div>
          </div>
          <div className="flex felx-row justify-between items-center mt-16">
            <p className="text-6xl font-bold ">
              Augment your team with digital construction workers!
            </p>
            {!submitted ? (
              <form
                id="contact-form"
                onSubmit={handleSubmit}
                className="flex flex-col items-center py-10 px-16 bg-white rounded-lg"
              >
                <div className="flex flex-row items-center">
                  <div className="mr-12">
                    <label
                      htmlFor="firstname"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      First Name
                    </label>
                    <div className="">
                      <div className="flex bg-white   ring-1 ring-inset ring-black focus-within:ring-2 focus-within:ring-inset focus-within:ring-amber-600  sm:max-w-md">
                        <input
                          type="text"
                          name="firstname"
                          id="firstname"
                          autoComplete="username"
                          value={firstname}
                          onChange={(e) => setFirstname(e.target.value)}
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 w-[200px] shadow-[5px_5px_0px_0px_#1a202c]"
                          placeholder="First Name"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="lastname"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Last Name
                    </label>
                    <div className="">
                      <div className="flex bg-white ring-1 ring-inset ring-black focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <input
                          type="text"
                          name="lastname"
                          id="lastname"
                          autoComplete="username"
                          value={lastname}
                          onChange={(e) => setLastname(e.target.value)}
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 w-[200px] shadow-[5px_5px_0px_0px_#1a202c]"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4"></div>
                <div className="mt-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email
                  </label>
                  <div className="mt-2">
                    <div className="flex bg-white  ring-1 ring-inset ring-black focus-within:ring-2 focus-within:ring-inset focus-within:ring-black sm:max-w-md">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        autoComplete="username"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 w-[500px] shadow-[5px_5px_0px_0px_#1a202c]"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="bg-amber-400 hover:bg-amber-200 shadow-[5px_5px_0px_0px_#1a202c] px-6 py-2 border-black border-2 text-black mt-8"
                >
                  Submit
                </button>
              </form>
            ) : (
              <div className="flex flex-col items-center py-10 px-16 bg-gray-100 rounded-lg">
                <p className="text-2xl font-bold">
                  Check your email, we just sent you the onboarding
                  instructions!
                </p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          id="footer"
          className="bg-black rounded-xl flex flex-col px-8 py-8"
        >
          <div className="mb-6">
            <div className="font-bold text-xl text-white ">Partners</div>
            <div className="flex flex-row items-center">
              {Object.keys(partners).map((key) => (
                <div className="mr-4 w-[20%]" key={key}>
                  {partners[key].image}
                </div>
              ))}
            </div>
          </div>
          <p className="text-white text-[28px] font-black">
            Augment your team with digital construction workers!
          </p>
          {!submitted ? (
            <form
              onSubmit={handleSubmit}
              className="flex flex-col py-6 px-4 text-white rounded-lg mt-6"
            >
              <div className="">
                <label
                  htmlFor="firstname"
                  className="block text-sm font-medium leading-6 "
                >
                  First Name
                </label>
                <div className="">
                  <div className="flex bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      name="firstname"
                      id="firstname"
                      autoComplete="username"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 w-[200px]"
                      placeholder="First Name"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <label
                  htmlFor="lastname"
                  className="block text-sm font-medium leading-6 "
                >
                  Last Name
                </label>
                <div className="">
                  <div className="flex bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      name="lastname"
                      id="lastname"
                      autoComplete="username"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 w-[200px]"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 "
                >
                  Email
                </label>
                <div className="">
                  <div className="flex bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      autoComplete="username"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 w-[500px]"
                      placeholder="Email"
                    />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="bg-amber-400 px-6 py-2 rounded text-white mt-8"
              >
                Submit
              </button>
            </form>
          ) : (
            <div className="flex flex-col py-6 px-4 bg-gray-100 rounded-lg mt-6">
              <p className="text-2xl font-bold text-white">
                Check your email, we just sent you the onboarding instructions!
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DWForm;
