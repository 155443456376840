import { React, useEffect, useState } from "react";
// import Header from "./components/Header";
import MeetingsHeader from "./components/Meetings-site/MeetingsHeader.js";
import DWHeader from "./components/DigitalWorkers-site/DWHeader";
import { Link } from "react-router-dom";
import { generateSlug } from "./utils";
// import { posts } from "../src/data/blogs.js";
import { fetchData, fetchAuthors } from "../src/data/blogs.js";
import MeetingsForm from "./components/Meetings-site/MeetingsForm.js";
import { scrollToSection } from "./utils";

const BlogsList = () => {
  const [posts, setPosts] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [authors, setAuthors] = useState([]);

  useEffect(() => {
    const getAuthors = async () => {
      try {
        const data = await fetchAuthors();
        setAuthors(data);
      } catch (error) {
        console.error("Error fetching authors:", error);
      }
    };

    getAuthors();
  }, []); // Empty dependency array ensures this runs once

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    async function fetchDataAndSetState() {
      try {
        const fetchedData = await fetchData();
        // Parse the JSON string in each post object
        const parsedData = fetchedData.map((post) => ({
          ...post,
          JSON: JSON.parse(post.JSON),
        }));
        setPosts(parsedData);
        console.log("blogs", parsedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchDataAndSetState();
  }, []);

  const scrollToDiv = () => {
    const destinationDiv = document.getElementById("footer");
    destinationDiv.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <DWHeader width={width} scroll={scrollToSection} />
      <div className="main bg-white md:w-full md:h-full  md:flex md:justify-center">
        <div className="main bg-white py-24 max-sm:py-24 2xl:max-w-[1440px] ">
          <div className=" max-sm:border-b max-sm:border-gray-200 max-sm:px-0 px-20 mb-16">
            <div className="mx-auto max-w-7xl px-6 lg:px-8  max-sm:border-l-0">
              <div className="mx-auto max-w-2xl lg:mx-0">
                <h2 className="text-6xl md:mt-16 font-bold tracking-tight text-gray-900 max-sm:text-4xl">
                  From the blog
                </h2>
              </div>
              <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 max-sm:mt-2 max-sm:pt-2 pt-10 sm:mt-2 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3 ">
                {posts.length > 0 ? (
                  posts.map((post) => (
                    <Link
                      key={post.id}
                      to={`/blog/${post.id}/${generateSlug(
                        post.JSON.title // Accessing title directly
                      )}`}
                    >
                      <article
                        key={post.id}
                        className={`flex max-w-xl flex-col items-start justify-between hover:bg-gray-100 p-2 rounded-lg bg-cover max-sm:mb-6`}
                      >
                        <img
                          src={post.JSON.cover_image} // Accessing cover_image directly
                          alt={post.JSON.title} // Accessing title directly
                          className="w-full rounded-lg mt-8"
                        />
                        <div className="flex items-center gap-x-4 text-xs mt-2">
                          <time
                            dateTime={post.datetime}
                            className="text-gray-500"
                          >
                            {post.date}
                          </time>
                          <div className="relative z-10 rounded-full bg-amber-400 px-3 py-1.5 font-medium text-gray-600 ">
                            {post.JSON.topic} {/* Accessing topic directly */}
                          </div>
                        </div>

                        <div className="group relative">
                          <h3 className="mt-2 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                            <a href={post.href}>
                              <span className="absolute inset-0" />
                              {post.JSON.title} {/* Accessing title directly */}
                            </a>
                          </h3>
                          <p className="mt-5 line-clamp-2 text-sm leading-6 text-gray-600">
                            {post.JSON.description}{" "}
                            {/* Accessing description directly */}
                          </p>
                        </div>
                        {authors.length > 0 ? (
                          <div className="relative mt-6 flex items-center gap-x-4">
                            <img
                              src={authors
                                .filter(
                                  (author) => author.name === post.JSON.author // Accessing author directly
                                )
                                .map((author) => author.photo)}
                              alt=""
                              className="h-10 w-10 rounded-full bg-gray-50"
                            />
                            <div className="text-sm leading-6">
                              <p className="font-semibold text-gray-900">
                                <span className="absolute inset-0" />
                                {authors
                                  .filter(
                                    (author) => author.name === post.JSON.author // Accessing author directly
                                  )
                                  .map((author) => author.name)}
                              </p>
                              <p className="text-gray-600">
                                {authors
                                  .filter(
                                    (author) => author.name === post.JSON.author // Accessing author directly
                                  )
                                  .map((author) => author.Role)}
                              </p>
                            </div>
                          </div>
                        ) : null}
                      </article>
                    </Link>
                  ))
                ) : (
                  <p>No posts available</p>
                )}
              </div>
            </div>
          </div>
          <MeetingsForm width={width} />
        </div>
      </div>
    </>
  );
};

export default BlogsList;
