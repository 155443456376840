import React, { useEffect, useState, useRef } from "react";
import logo from "../../img/logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineMenu } from "react-icons/md";
import AttentionBanner from "./AttentionBanner";

const DWHeader = ({ scroll, width }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [backgroundBlack, setBackgroundBlack] = useState(false);
  // const [width, setWidth] = useState(window.innerWidth);
  const [openMenu, setOpenMenu] = useState(false);
  const [openFeatures, setOpenFeatures] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavClick = (section) => {
    // Navigate to the homepage and include the section hash
    navigate(`/#${section}`, { replace: false });

    // Scroll to the section manually when already on the homepage
    const sectionElement = document.getElementById(section);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const background = document.getElementsByClassName("main");
    if (background && background[0]) {
      for (let c of background[0].classList) {
        if (c === "bg-black") {
          setBackgroundBlack(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", updatePosition);

    updatePosition();

    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWidth(window.innerWidth);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  const handleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const menuClick = (section) => {
    scroll(section);
    if (openFeatures) {
      // Only close the dropdown if it's open
      setOpenFeatures(false);
    }
  };

  return (
    <>
      {width > 660 ? (
     
          

        <div
          id="nav-bar"
          className={` z-40 fixed w-full 2xl:px-96 flex flex-col justify-between pb-8 ${
            scrollPosition < 60 ? "" : "bg-black"
            // scrollPosition < 60 ? "" : "bg-[#000000E5]"
          }`}
        >
          <AttentionBanner />
          <div className="px-20 mt-5">
            <div className="flex flex-row justify-between">
              <Link to="/">
                <img
                  src={logo}
                  alt="logo"
                  className={`w-[50%] ${
                    scrollPosition < 60 ? "w-[20%]" : "w-[12%]"
                  }`}
                />
              </Link>
              <div className="flex flex-row items-center">
                <Link
                  to="/#how-it-works"
                  onClick={() => handleNavClick("how-it-works")}
                >
                  <button
                    className={`font-bold hover:underline mr-12 ${
                      backgroundBlack || scrollPosition > 60
                        ? "text-white"
                        : "text-black"
                    }`}
                    // className="text-black text-xl font-bold bg-white px-3 py-2 border-2 border-black shadow-md"

                    // onClick={() => menuClick("how-minutes-work")}
                  >
                    Digital Workers
                  </button>
                </Link>
              
                <Link to="/blog" className="">
                  <div className="  flex mr-12">
                    <span
                      className={`font-bold hover:underline ${
                        backgroundBlack || scrollPosition > 60
                          ? "text-white"
                          : "text-black"
                      }`}
                    >
                      Blog
                    </span>
                  </div>
                </Link>
                <Link to="/ebook" className="">
                  <div className="  flex mr-12">
                    <span
                      className={`font-bold hover:underline ${
                        backgroundBlack || scrollPosition > 60
                          ? "text-white"
                          : "text-black"
                      }`}
                    >
                      Ai Primer
                    </span>
                  </div>
                </Link>

                <div className="w-[60px]  flex mr-12">
                  <span
                    className={`font-bold hover:underline ${
                      backgroundBlack || scrollPosition > 60
                        ? "text-white"
                        : "text-black"
                    }`}
                  >
                    <a
                      href="https://app.flowlly.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Login
                    </a>
                  </span>
                </div>

                <button
                  className={`text-black text-xl font-bold px-3 py-2 border-2 border-black  hover:bg-amber-400 bg-white ${
                    scrollPosition < 60 ? "shadow-[5px_5px_0px_0px_#1a202c]" : ""
                  }`}
                  // className="text-black text-xl font-bold bg-white px-3 py-2 border-2 border-black shadow-md"


                  onClick={() => scroll("footer")}

                >
                  Try It Now
                </button>
              </div>
            </div>

          </div>
        </div>
   

      ) : (
        <div className="py-4 px-4 fixed flex flex-row justify-between z-40">
          <Link to="/">
            <img src={logo} alt="logo" className="w-[35%]" />
          </Link>
          <div
            className={`border rounded-md ${
              backgroundBlack
                ? "border-white bg-black "
                : "border-gray-500 bg-white"
            }`}
            onClick={() => handleMenu()}
          >
            <MdOutlineMenu className="text-2xl" />
          </div>
          {openMenu && (
            <div className="noselect flex flex-col text-black text-lg border border-gray-100 absolute right-4 top-12 p-4 bg-white shadow-lg rounded">
              <Link
                to="/#how-it-works"
                onClick={() => handleNavClick("how-it-works")}
                className="mb-2"
              >
                <buton

                // onClick={() => scroll("how-minutes-work")}
                >
                  Digital Workers
                </buton>
              </Link>

              {/* <buton className="mb-2" onClick={() => scroll("features-mobile")}>
                Features
              </buton> */}
              {/* <buton
                className="mb-2"
                onClick={() => scroll("how-it-works-mobile")}
              >
                How it Works
              </buton> */}
              <Link to="/blog" className="mb-2">
                <span>Blog</span>
              </Link>
              <Link to="/ebook" className="mb-2">
                <span>Ai Primer</span>
              </Link>

              <span className="font-bold" onClick={() => handleNavClick("footer")}>
                Try It Now
              </span>
            </div>
          )}
          {/* <div
          className="bg-white text-black py-1 px-2 rounded-full shadow-lg"
          onClick={scroll}
        >
          Contact Us
        </div> */}
        </div>
      )}
    </>
  );
};

export default DWHeader;
